import React from 'react'
import cx from 'classnames'

export default ({ classes = null }) => {
  return (
    <svg className={cx(classes)} viewBox="0 0 51 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3256 13.416L30.3566 8.362L25.4506 0.076C25.4196 0.025 25.3716 0 25.3236 0C25.298 0.000204683 25.2729 0.00738828 25.2511 0.0207787C25.2292 0.0341692 25.2115 0.0532602 25.1996 0.076L20.2896 8.362L25.3236 13.416H25.3256ZM43.2636 14.693L50.2376 10.992C50.5176 10.845 50.7766 11.233 50.5746 11.497C50.5746 11.497 41.2286 23.799 38.9936 26.813C38.7226 27.18 38.2976 27.349 37.8796 27.263C33.7266 26.429 29.5246 26.011 25.3236 26.011C21.1075 26.0118 16.9019 26.4312 12.7686 27.263C12.3506 27.35 11.9236 27.18 11.6526 26.813C9.42064 23.799 0.0706384 11.497 0.0706384 11.497C-0.129362 11.233 0.130638 10.845 0.410638 10.993L7.38364 14.693C8.42064 15.235 9.40964 16.145 9.64364 17.405L10.6696 22.657C10.8626 23.215 11.3416 23.567 11.8596 23.567C11.9416 23.567 12.0266 23.56 12.1096 23.54V9.126L10.6876 7.526H15.2606L25.3206 17.79L25.3236 17.788L25.3256 17.79L35.3876 7.524H39.9616L38.5386 9.124V23.54C39.1446 23.675 39.7536 23.304 39.9786 22.657L41.0036 17.405C41.2396 16.145 42.2286 15.235 43.2636 14.693ZM15.7296 13.44V22.875C18.5648 22.4458 21.4256 22.2073 24.2926 22.161L15.7296 13.44ZM26.3536 22.162C29.2217 22.2083 32.0835 22.4468 34.9196 22.876V13.441L26.3536 22.162ZM36.8276 30.318L37.7946 28.905C33.6696 28.083 29.4966 27.672 25.3236 27.672C21.1366 27.6726 16.9597 28.0856 12.8536 28.905C13.1756 29.373 13.4976 29.843 13.8186 30.318C14.0836 30.707 14.5176 30.901 14.9486 30.83C18.3886 30.261 21.8576 29.976 25.3236 29.976C28.7995 29.9767 32.2694 30.2624 35.6986 30.83C36.1316 30.901 36.5626 30.707 36.8276 30.318Z"
        fill="white"
      />
    </svg>
  )
}
