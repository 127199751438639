import React from 'react'

export default () => {
  return (
    <svg width="23" height="30" viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 16.2941L18.2222 1L10.1778 12.7059H22L3.88889 29L12.2889 16.2941H1Z"
        stroke="#0066A6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
