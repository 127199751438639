import React from 'react'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'
import cx from 'classnames'
import { prepareParagraph } from '../../../utils/format'

import styles from './style.module.sass'

export default ({ aboutEstrogen, blockId = 'estrogen' }) => {
  return (
    <Container classes={styles.outer}>
      <Wrapper classes={styles.wrapper}>
        <Item classes={styles.contentContainer} id={blockId}>
          <Title>What about Estrogen?</Title>
        </Item>
        <Item classes={styles.contentContainer}>
          <div className={styles.blueText}>{aboutEstrogen.blueHeader}</div>
          {aboutEstrogen.paragraphs &&
            aboutEstrogen.paragraphs.map(paragraph => (
              <div key={paragraph._key} className={styles.grayText}>
                {prepareParagraph(paragraph.text)}
              </div>
            ))}
        </Item>
      </Wrapper>
      <Wrapper classes={styles.wrapper}>
        <Item classes={styles.contentContainer}>
          <div className={styles.border} />
          <h3 className={cx(styles.heading, styles.withLine)}>{aboutEstrogen.lowEstrogen.title}</h3>
          {aboutEstrogen.lowEstrogen.paragraphs &&
            aboutEstrogen.lowEstrogen.paragraphs.map(paragraph => (
              <div key={paragraph._key} className={styles.grayText}>
                {prepareParagraph(paragraph.text)}
              </div>
            ))}
        </Item>
      </Wrapper>
    </Container>
  )
}
