import React from 'react'

export default () => {
  return (
    <svg width="26" height="33" viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.7907 22.923C3.7907 26.7455 5.88372 30.7318 16 31.6104C6.5814 33.3479 1 29.0643 1 22.923C1 18.7531 2.43333 16.2094 4.83721 13.5407C5.88372 10.4488 11.0291 7.63336 12.8605 9.7183C13.7762 10.7608 11.814 15.2781 7.62791 16.6681C6.23256 18.1009 3.7907 19.7956 3.7907 22.923Z"
        stroke="#0066A6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2093 10.077C22.2093 6.25451 20.1163 2.26817 10 1.38958C19.4186 -0.347891 25 3.93566 25 10.077C25 14.2469 23.5667 16.7906 21.1628 19.4593C20.1163 22.5512 14.9709 25.3666 13.1395 23.2817C12.2238 22.2392 14.186 17.7219 18.3721 16.3319C19.7674 14.8991 22.2093 13.2044 22.2093 10.077Z"
        stroke="#0066A6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
