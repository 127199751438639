import React from 'react'

export default () => {
  return (
    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25 7.16265C25 14.7967 13.0001 21 13.0001 21C13.0001 21 1 14.7968 1 7.1627C1 -0.471444 11.0409 -0.709985 13.0001 4.777C14.9593 -0.710045 25 -0.471391 25 7.16265Z"
        stroke="#0066A6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
