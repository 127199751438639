import React from 'react'

export default props => {
  return (
    <svg width="1260" height="1662" viewBox="0 0 1260 1662" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M304.714 12.4906C304.714 17.6498 301.271 22.2658 295.963 24.1666C173.733 67.0692 100.854 206.639 88.9466 271.807C87.5119 280.225 79.765 286.334 70.5834 286.334C65.1318 286.334 59.8238 284.026 56.3807 280.089C39.4521 260.946 0 207.589 0 125.314C0 77.795 41.0302 42.7668 121.799 21.4513C187.792 3.80145 262.249 0.543071 291.228 0H291.515C294.958 0 298.258 1.2219 300.697 3.52996C303.279 5.83801 304.714 9.09644 304.714 12.4906Z"
        fill="#F2FAFF"
      />
      <path
        d="M960.63 897.125C962.065 897.125 963.643 897.396 965.078 897.939C999.078 910.973 1074.25 950.889 1139.53 1017.55C1217.86 1096.84 1259.32 1190.25 1259.32 1287.73C1259.32 1371.23 1210.83 1462.73 1126.19 1538.76C1049.29 1607.87 952.309 1654.98 873.262 1661.77C868.671 1662.18 863.937 1660.82 860.493 1657.83C857.481 1655.12 855.759 1651.59 855.759 1647.79C855.759 1643.17 858.342 1638.82 862.789 1636.24C897.22 1616.02 1068.66 1504.28 1068.66 1288.95C1068.66 1057.35 992.204 962.987 951.171 912.343L951.162 912.331C949.584 910.294 948.866 907.986 949.297 905.542C949.727 902.963 951.305 900.655 953.744 899.161C955.752 897.804 958.191 897.125 960.63 897.125Z"
        fill="#F2FAFF"
      />
      <path
        d="M448.857 297.067C442.975 321.505 429.059 386.13 429.059 458.223C429.059 540.686 487.138 612.615 543.236 682.091L543.685 682.647C600.783 753.111 659.746 826.018 659.746 910.33C659.746 978.486 647.408 1013.38 642.1 1025.33C640.953 1027.77 638.37 1029.4 635.501 1029.4C631.771 1029.4 628.758 1026.68 628.328 1023.15C627.467 1013.92 623.881 998.579 612.69 978.893C600.496 957.442 563.626 933.547 516.858 903.406C421.455 841.767 290.905 757.32 290.905 634.314C290.905 494.745 408.974 323.134 432.645 290.142C434.367 287.834 437.236 286.477 440.105 286.477C441.397 286.477 442.831 286.884 444.122 287.427C447.852 289.192 449.717 293.129 448.857 297.067Z"
        fill="#F2FAFF"
      />
    </svg>
  )
}
