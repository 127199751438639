import React from 'react'

export default () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.1952 6.83158C31.3752 4.65161 27.7243 1.79347 25.5579 3.19419C23.5725 4.4778 24.2877 9.05889 24.2877 9.05889L7.63992 25.7067C7.63992 25.7067 3.77048 24.2157 2.56799 26.1841C0.997317 28.7551 4.22341 31.8034 6.20538 29.8214C4.22341 31.8034 7.38215 34.9622 9.84277 33.4588C11.275 32.5838 10.3201 28.3869 10.3201 28.3869L26.9679 11.7391C26.9679 11.7391 30.4543 12.8473 32.8326 10.469C34.6162 8.68535 31.3752 4.65161 29.1952 6.83158Z"
        stroke="#0066A6"
        strokeLinecap="round"
        strokeLinejoin="miter"
      />
    </svg>
  )
}
