import React, { useEffect } from 'react'
import styles from './style.module.sass'

import { prepareParagraph } from '../../../utils/format'

export default ({ references }) => {
  useEffect(() => {
    const autorefLinks = document.getElementsByClassName('autoref')
    Array.from(autorefLinks).forEach((item, index) => {
      item.href = item.href.replace('[AUTOREF_COUNT]', index + 1)
      item.innerText = index + 1
    })
  }, [])

  return (
    <div className={styles.refs}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{references.title}</h2>
        <div className={styles.box}>
          <ul className={styles.list}>
            {references.items &&
              references.items.map((item, index) => (
                <li key={item._key} className={styles.item}>
                  <div className={styles.author} id={`reference_${index + 1}`}>
                    {index + 1}. {prepareParagraph(item.text)}
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
