import React, { useState, useEffect } from 'react'

import cx from 'classnames'

import styles from './style.module.sass'

export default ({ classes, anchors, whiteThemeContainerId = null }) => {
  const [currentId, setCurrentId] = useState(anchors[0].id)

  const OFFSET = 50
  const ANCHOR_PREFIX_ID = 'anchor-'

  const getOffsetTop = element => {
    let offsetTop = 0
    while (element) {
      offsetTop += element.offsetTop
      element = element.offsetParent
    }
    return offsetTop
  }

  const findAnchor = () => {
    let winScroll = document.body.scrollTop || document.documentElement.scrollTop
    let index = anchors.length - 1
    let anchor = null
    let element = null

    do {
      anchor = anchors[index]
      element = document.getElementById(anchor.id)
      index = index - 1
    } while (winScroll < getOffsetTop(element) - OFFSET && index >= 0)
    return anchor
  }

  const scrollHandler = () => {
    if (!window) return false

    let anchor = findAnchor()
    setCurrentId((anchor || {}).id)

    if (whiteThemeContainerId) {
      let container = document.getElementById(whiteThemeContainerId)
      let winScroll = document.body.scrollTop || document.documentElement.scrollTop

      anchors.forEach(anchor => {
        let element = document.getElementById(anchorId(anchor.id))
        let offsetTop = getOffsetTop(element)

        if (offsetTop + winScroll < container.offsetHeight) {
          element.classList.add(styles.whiteTheme)
        } else {
          element.classList.remove(styles.whiteTheme)
        }
      })
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  const anchorId = id => {
    return `${ANCHOR_PREFIX_ID}${id}`
  }
  return (
    <div className={cx(styles.anchors, classes)}>
      <div>
        {anchors &&
          anchors.map(anchor => {
            return (
              <div key={anchor.id}>
                <a
                  className={cx(styles.anchor, styles.whiteTheme, {
                    [styles.active]: currentId === anchor.id,
                  })}
                  href={`#${anchor.id}`}
                  id={anchorId(anchor.id)}
                >
                  {anchor.title}
                </a>
              </div>
            )
          })}
      </div>
    </div>
  )
}
