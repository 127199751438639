import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import FaqSchema from '../components/Shared/Schema/Faq'
import WhatIsTestosterone from '../components/Testosterone101Page/WhatIsTestosterone'
import MarkersAndMeasurements from '../components/Testosterone101Page/MarkersAndMeasurements'
import TestosteroneLevels from '../components/Testosterone101Page/TestosteroneLevels'
import HowMade from '../components/Testosterone101Page/HowMade'
import AboutEstrogen from '../components/Testosterone101Page/AboutEstrogen'
import BodyOptimization from '../components/Testosterone101Page/BodyOptimization'
import AccessToCommunity from '../components/Testosterone101Page/AccessToCommunity'
import TestosteroneAnchors from '../components/Shared/UI/TestosteroneAnchors'
import Faq from '../components/Testosterone101Page/Faq'
import References from '../components/Testosterone101Page/References'
import styles from '../styles/testosterone.module.sass'

export default ({ pageContext }) => {
  const { data } = pageContext
  const heroContainerId = 'maximusHeroID'

  const anchors = [
    { title: 'T 101', id: 't_101' },
    { title: 'T MARKERS', id: 't_markers' },
    { title: 'T LEVELS', id: 't_levels' },
    { title: 'HOW IT’S MADE', id: 'how_it_s_made' },
    { title: 'ESTROGEN', id: 'estrogen' },
    { title: 'BODY IMPACT', id: 'body_impact' },
  ]

  return (
    <Layout
      data={data}
      headerClasses={styles.headerStyle}
      heroBodyClasses={styles.heroBody}
      heroContainerId={heroContainerId}
    >
      <SEO {...data.seo} />
      {data?.faq?.items && <FaqSchema questions={data.faq.items} />}
      <TestosteroneAnchors anchors={anchors} whiteThemeContainerId={heroContainerId} />
      <WhatIsTestosterone whatIsTestosterone={data.whatIsTestosterone} />
      <AccessToCommunity />
      <MarkersAndMeasurements markersAndMeasurements={data.markersAndMeasurements} />
      <TestosteroneLevels testosteroneLevels={data.testosteroneLevels} />
      <AccessToCommunity />
      <HowMade howMade={data.howMade} />
      <AboutEstrogen aboutEstrogen={data.aboutEstrogen} />
      <BodyOptimization bodyOptimization={data.bodyOptimization} />
      <AccessToCommunity />
      <Faq faq={data.faq} />
      <References references={data.references} />
    </Layout>
  )
}
