import React from 'react'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'
import BgLion from '../../Shared/Icons/BgLion'

import cx from 'classnames'

import styles from './style.module.sass'

import { prepareParagraph } from '../../../utils/format'

export default ({ markersAndMeasurements, blockId = 't_markers' }) => {
  return (
    <Container classes={styles.outer}>
      <Item classes={styles.lion}>
        <BgLion />
      </Item>
      <Wrapper classes={styles.wrapper}>
        <Item classes={cx(styles.contentContainer, styles.stretch)} id={blockId}>
          <Title>Testosterone Markers & Measurements:</Title>
          <div className={styles.details}>
            <div className={styles.detailsHeader}>1 - 3%</div>
            <div className={styles.detailsContent}>Average of Total Testosterone is considered free</div>
          </div>
          <div className={styles.details}>
            <div className={styles.detailsHeader}>50%</div>
            <div className={styles.detailsContent}>The most abundant protein in your blood</div>
          </div>
        </Item>
        <Item classes={styles.contentContainer}>
          <div className={styles.blueText}>{markersAndMeasurements.blueHeader}</div>
          {markersAndMeasurements.paragraphs &&
            markersAndMeasurements.paragraphs.map(paragraph => (
              <div key={paragraph._key} className={styles.paragraph}>
                <div className={styles.paragraphNumber}>{paragraph.number}</div>
                <h3 className={styles.subHeader}>{paragraph.header}</h3>
                <div className={styles.grayText}>{prepareParagraph(paragraph.text)}</div>
              </div>
            ))}
          <div className={styles.paragraph}>
            <div className={styles.paragraphCenter}>{markersAndMeasurements.additional.header}</div>
            <div className={styles.grayText}>{prepareParagraph(markersAndMeasurements.additional.text)}</div>
          </div>
        </Item>
      </Wrapper>
    </Container>
  )
}
