import React from 'react'

export default () => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17" r="16" stroke="#0066A6" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M25 1C25 7.62742 19.6274 13 13 13C6.37258 13 1 7.62742 1 1"
        stroke="#0066A6"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(4,15)"
      />
    </svg>
  )
}
