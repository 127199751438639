import React from 'react'

export default () => {
  return (
    <svg width="29" height="26" viewBox="0 0 29 26" fill="none">
      <path
        d="M1 16.5072C6.05915 14.0807 9.9508 12.0586 15.3991 5.9924C15.3991 1.13938 25.5174 -0.882654 27.4632 3.1615C29.4476 7.28576 25.5174 12.4631 22.4041 13.6763C18.6086 17.4172 13.0641 22.1691 7.61581 25"
        stroke="#0066A6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
