import React from 'react'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/format'
import cx from 'classnames'

import styles from './style.module.sass'

export default ({ bodyOptimization, blockId = 'body_impact' }) => {
  return (
    <div className={styles.box}>
      <div className={styles.blueBackground}></div>
      <Container classes={styles.outer}>
        <Wrapper classes={styles.wrapper}>
          <Item classes={styles.contentContainer} id={blockId}>
            <Title>What does optimized Testosterone do to the body?</Title>
          </Item>
          <Item classes={styles.contentContainer}>
            <div className={styles.blueText}>{bodyOptimization.blueHeader}</div>
            {bodyOptimization.paragraphs &&
              bodyOptimization.paragraphs.map(paragraph => (
                <div key={paragraph._key} className={styles.grayText}>
                  {prepareParagraph(paragraph.text)}
                </div>
              ))}
          </Item>
        </Wrapper>
        <Wrapper classes={styles.wrapper}>
          <Item classes={styles.contentContainer}>
            {bodyOptimization.items &&
              bodyOptimization.items.map(item => {
                return (
                  <div key={item._key} className={styles.item}>
                    <div className={styles.border} />
                    <h3 className={cx(styles.heading, styles.withLine)}>{item.title}</h3>
                    {item.paragraphs &&
                      item.paragraphs.map(paragraph => (
                        <div key={paragraph._key} className={styles.grayText}>
                          {prepareParagraph(paragraph.text)}
                        </div>
                      ))}
                    {item.image && (
                      <img
                        className={styles.image}
                        {...srcSetProps(sanityImageUrl(item.image))}
                        alt={item.image?.caption}
                      />
                    )}
                  </div>
                )
              })}
          </Item>
        </Wrapper>
      </Container>
    </div>
  )
}
